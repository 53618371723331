/* The Cult was here. 
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@                                                  @@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@                                                  @@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@                                                  @@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@                                                  @@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@          @@@@@@@@@@          @@@@@@@@@@          @@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@          @@@@@@@@@@          @@@@@@@@@@          @@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@          @@@@@@@@@@          @@@@@@@@@@          @@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@          @@@@@@@@@@          @@@@@@@@@@          @@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@          @@@@@@@@@@          @@@@@@@@@@          @@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@          @@@@@@@@@@          @@@@@@@@@@          @@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@          @@@@@@@@@@          @@@@@@@@@@          @@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@          @@@@@@@@@@          @@@@@@@@@@          @@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@                                                  @@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@                                                  @@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@                                                  @@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@                                                  @@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@                              @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
*/

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bangers&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
/* Global */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}
button,
h1,
h2 {
  font-family: "Roboto", sans-serif;
}
h3 {
  font-family: "Roboto", sans-serif;
}
a {
  text-decoration: none;
  color: white;
}
button a {
  color: #fff;
  font-family: "Bangers", cursive;
}
button a:hover {
  color: #fff;
}
input {
  border-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  background-color: #eee;
}
.no-outline:focus {
  outline: hidden;
}
.logo h4 {
  display: inline;
  font-size: 32px;
  color: black;
  font-weight: bolder;
}
.logo {
  height: 75px;
  padding-top: 10px;
}
a {
  color: white;
  cursor: pointer;
}
button {
  text-decoration: none;
  outline: none;
  border: 0;
}
ul {
  list-style: none;
}
body html {
  overflow-x: initial !important;
  min-height: 100%;
}
.active {
  font-weight: bold;
}
.navbar {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 75px;
  justify-content: flex-end;
  padding-left: 20px;
  color: white;
  background-color: #000000;
  box-shadow: 0px 0px 5px black;
}
.navbar button {
  padding: 10px;
  width: 150px;
  background-color: black;
  color: white;
  border-style: solid;
  border-width: medium;
  border-color: #ffffff;
  font-size: 18px;
  cursor: pointer;
}
.navbar button a {
  width: 100px;
  color: white;
  font-size: 18px;
  cursor: pointer;
}
.navbar li a {
  color: white;
}
.navbar li a:hover {
  color: white;
}
.navbar button:hover {
  background-color: white;
  color: black;
}
.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
}
.nav-links li {
  margin: 0 10px;
  display: inline-block;
  padding: 0px 20px;
}
.nav-links li a {
  transition: all 0.3 ease 0s;
}
li .connect-btn a {
  color: white;
}
.logo {
  position: absolute;
  left: 20px;
}
header {
  background-position: center top;
  background-size: cover;
  display: flex;
  align-items: left;
  justify-content: left;
  background-color: #000;
}

.wrap {
  margin-top: 75px;
  width: 100%;
}

.floatleft {
  float: left;
  width: 50%;

  background-position: center top;
  background-size: cover;
  height: 80vh;
}

.floatright {
  float: right;
  background-image: url("./images/skull-toy.png");
  background-position: center top;
  background-size: cover;
  height: 80vh;
  width: 50%;
}
header h3 {
  font-size: 24px;
  color: white;
  margin-bottom: 20px;
}
header .pop-skully {
  position: relative;
}
.mint-text {
  width: 100%;
  text-align: left;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 300px;
}

.mint-text h1 {
  font-size: 36px;
  margin-bottom: 15px;
  color: #ffffff;
  font-weight: bolder;
}
.mint-text h2 {
  font-size: 18px;
  text-align: left;
  color: #fff;
  padding-bottom: 15px;
}
.mint-text button {
  padding: 10px;
  width: 253px;
  background-color: black;
  color: white;
  border-style: solid;
  border-width: medium;
  border-color: #ffffff;
  font-size: 18px;
  cursor: pointer;
}
.mint-text button:hover {
  background-color: white;
  color: black;
}
.mint-text button a {
  padding: 5px;
  width: 100px;

  color: #000000;
  font-size: 18px;
  cursor: pointer;
}
.mint-text h4 {
  color: whitesmoke;
}
.mint-text input {
  height: 40px;
  margin-top: 0px;
  padding-left: 15px;
  position: left;
  border-style: none;
  outline-style: solid;
  background-color: black;
  outline-color: #ffffff;
  color: white;
  width: 250px;
}
.header-content p {
  font-size: 2vmin;
  color: white;
}

.mint-text h3 {
  text-align: left;
  margin-top: 15px;
}
/*Dashboard Button*/
.header-content {
  width: 100%;
}
section {
  width: 80%;
  margin: 0px auto;
}
.info {
  width: 100vw;
  background-image: url("./images/bg-img.png");
  background-position: center top;
  background-size: cover;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-top: 0px;
  color: white;
  text-align: left;
}
.info h1 {
  font-family: "Bangers", cursive;
}
.info h4 {
  color: white;
}
.info a {
  font-weight: bold;
}
.previews {
  width: 100%;
  height: 100px;
  padding: 0px;
}
.previews img {
  width: 25%;
  float: left;
}

.card-links li {
  margin-bottom: 5px;
}
.card-links a {
  color: #cccccc;
}
.card-links a:hover {
  color: black;
}

.info .row .col {
  text-align: left;
}

h1 {
  font-weight: lighter;
  font-size: 36px;
}
h4 {
  font-size: 18px;
  margin: 20px auto;
  font-weight: 1;
  color: grey;
}
p {
  font-size: 12px;
  color: grey;
  padding: 0px 0px;
}

.footer {
  width: 100%;
  height: 100%;
  padding: 20px 80px;
  margin: 0;
  background: #000000;
  text-align: center;
}
.footer p {
  font-size: 14px;
}

/*
Possible gradient for footer
linear-gradient(to right, #446e61, #b5ddeb);
*/
.footer p {
  color: whitesmoke;
  margin: 20px auto;
}
/* MENU BUTTON */
.menu-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 40px;
  cursor: pointer;
  display: none;
}
/* ANIMATIONS */
.social-links img {
  height: 20px;
  margin: 20px;
  cursor: pointer;
  margin-top: 40px;
}
.social-links {
  text-align: center;
}
li:hover {
  color: lightgrey;
  cursor: pointer;
}

/* Styling for mobile */
@media only screen and (max-width: 850px) {
  .menu-btn {
    display: block;
    margin-top: -15px;
  }
  .navbar {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 75px;
    justify-content: space-between;
    padding: 0px;
    color: white;
    z-index: 99;
  }
  p {
    font-size: 20px;
  }

  .nav-links {
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: center;
    background: black;
    margin-top: -900px;
    padding: 0px;
  }
  .mobile-menu {
    margin-top: 0px;
  }
  header h1 {
    margin-bottom: 10px;
    font-size: 92px;
  }
  header .pop-skully {
    display: none;
  }
  .nav-links li {
    margin: 30px auto;
  }
  .floatleft {
    float: left;
    width: 100vw;
    background-color: #000;
    height: 50vh;
  }

  .floatright {
    float: right;
    background-image: url("./images/skull-toy.png");
    background-position: center center;
    background-size: fill;
    height: 80vh;
    width: 100%;
  }
  .mint-text {
    padding-top: 50px;
  }
  .team p {
    font-size: 12px;
  }
  .card {
    width: 300px;
  }

  /* NEW TO WEB3? */
  .wallets {
    margin-top: 0px;
  }
  .row .col h1 {
    text-align: left;
  }
  .wallets .col {
    width: 50%;
    padding: 0px;
  }
  .wallets .card {
    margin-top: 50px;
    max-width: 400px;
    width: 70vw;
    height: 300px;
  }
  .wallets .card p {
    margin-top: 0px;
  }
  .wallets .wallet-cards .card img {
    margin-bottom: 10px;
  }
  .wallets .card .card-links {
    margin-top: 40px;
  }

  .wallets .row {
    flex-direction: column;
  }
  .wallets .row .col {
    width: 70vw;
  }

  .team .row {
    flex-direction: column;
  }
  .team .row .col {
    margin: 20px auto;
  }
  .mint-text .row {
    flex-direction: column;
  }
  .mint-text .row .col {
    margin: 20px auto;
  }
  .footer {
    padding: 10px;
  }
}
@media only screen and (max-width: 500px) {
  .wallets .card {
    height: 320px;
  }
  .wallets .wallet-cards .card img {
    margin-bottom: 5px;
  }
}
