.modal {
    display: flex;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    justify-content: center;
    align-items: center;
}

.modal-content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 50%;
    height: 50%;
    overflow: auto;
    background-color: dimgrey;
}

.modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.modal-body {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.modal-body-left {
    display: flex;
    flex-direction: column;
    width: 50%;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-left: 3rem;
}

.modal-body-right {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
}

.token-img {
    display: flex;
    height: 250px;
    width: 250px;
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }